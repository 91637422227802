const $ = window.jQuery

$(() => {
  const $languagePicker = $('.languagepicker')

  const isOpen = () => $languagePicker.find('.languagepicker-arrow').hasClass('languagepicker-arrow--up')

  const toggleOpen = (e) => {
    const $target = $(e.target)
    if($target.closest('.languagepicker-dropdown').length === 0) {
      $languagePicker.find('.languagepicker-arrow').toggleClass('languagepicker-arrow--up').toggleClass('languagepicker-arrow--down')
      $languagePicker.find('.languagepicker-dropdown').slideToggle(100)
      return false
    }
  }

  $languagePicker.on('touchstart click', e => {
    return toggleOpen(e)
  })

  $(document).on('touchstart click', e => {
    if(isOpen()) {
      toggleOpen(e)
    }
  })
})

'use strict';

$(function () {
  /*##################
  Slick
  ##################*/

  $('.slideshow').slick({
    dots: true,
    slide: '.slideshow-slide',
    autoplay: true,
    autoplaySpeed: 7500,
    speed: 1000
  });

  /*##################
  Umbraco form scroll
  ##################*/

  if ($('.commonform').length) {
    if ($('.validation-summary-errors').length) {
      $(window).scrollTop($('.validation-summary-errors').offset().top - 20);
    } else if ($('input[name="FormStep"]').val() != '0') {
      $(window).scrollTop($('.commonform').offset().top - 20);
    }
  }

  /*##################
  Sticky footer
  ##################*/

  var oldHeight = -1;

  var onResize = function onResize() {
    var footer = $('footer.footer');
    var footerHeight = footer.innerHeight();
    if (oldHeight === footerHeight) return; // skip if the height has not changed since last time

    $('.sticky-footer-page-content-wrapper').css({
      marginBottom: +(footerHeight * -1) + 'px'
    });
    $('#sticky-footer-styling').remove();
    $("<style id='sticky-footer-styling'>.sticky-footer-page-content-wrapper::after{height:" + footerHeight + 'px !important} </style>').appendTo('head');
  };

  $(window).resize(onResize);

  onResize();

  /*##################
  Mobile menu
  ##################*/

  $('.header-burger').click(function (event) {
    var mobilemenu = $('.mobilemenu');
    var burger = $('.header-burger');

    burger.toggleClass('header-burger--active');
    mobilemenu.toggleClass('mobilemenu--active');

    return false;
  });

  $('.mobilemenu-item--hasmenu > a').click(function () {
    $(this).parent().toggleClass('mobilemenu-item--active');
    return false;
  });
});
'use strict';

var $ = window.jQuery;

$(function () {
  var $languagePicker = $('.languagepicker');

  var isOpen = function isOpen() {
    return $languagePicker.find('.languagepicker-arrow').hasClass('languagepicker-arrow--up');
  };

  var toggleOpen = function toggleOpen(e) {
    var $target = $(e.target);
    if ($target.closest('.languagepicker-dropdown').length === 0) {
      $languagePicker.find('.languagepicker-arrow').toggleClass('languagepicker-arrow--up').toggleClass('languagepicker-arrow--down');
      $languagePicker.find('.languagepicker-dropdown').slideToggle(100);
      return false;
    }
  };

  $languagePicker.on('touchstart click', function (e) {
    return toggleOpen(e);
  });

  $(document).on('touchstart click', function (e) {
    if (isOpen()) {
      toggleOpen(e);
    }
  });
});
$(function () {
  /*##################
  Slick
  ##################*/

  $('.slideshow').slick({
    dots: true,
    slide: '.slideshow-slide',
    autoplay: true,
    autoplaySpeed: 7500,
    speed: 1000
  })

  /*##################
  Umbraco form scroll
  ##################*/

  if ($('.commonform').length) {
    if ($('.validation-summary-errors').length) {
      $(window).scrollTop($('.validation-summary-errors').offset().top - 20)
    } else if ($('input[name="FormStep"]').val() != '0') {
      $(window).scrollTop($('.commonform').offset().top - 20)
    }
  }

  /*##################
  Sticky footer
  ##################*/

  var oldHeight = -1

  var onResize = function () {
    var footer = $('footer.footer')
    var footerHeight = footer.innerHeight()
    if (oldHeight === footerHeight) return // skip if the height has not changed since last time

    $('.sticky-footer-page-content-wrapper').css({
      marginBottom: +(footerHeight * -1) + 'px'
    })
    $('#sticky-footer-styling').remove()
    $(
      "<style id='sticky-footer-styling'>.sticky-footer-page-content-wrapper::after{height:" +
        footerHeight +
        'px !important} </style>'
    ).appendTo('head')
  }

  $(window).resize(onResize)

  onResize()

  /*##################
  Mobile menu
  ##################*/

  $('.header-burger').click(function (event) {
    var mobilemenu = $('.mobilemenu')
    var burger = $('.header-burger')

    burger.toggleClass('header-burger--active')
    mobilemenu.toggleClass('mobilemenu--active')

    return false
  })

  $('.mobilemenu-item--hasmenu > a').click(function () {
    $(this)
      .parent()
      .toggleClass('mobilemenu-item--active')
    return false
  })
})
